import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { appActions, getAppState } from '../../../app/appSlice';
import { videos } from './init';

export default function UrbaneaseAcademySlider(): JSX.Element {
  const dispatch = useAppDispatch();
  const { accountFaqCurrentSlide } = useAppSelector(getAppState);
  const [currentVideoId, setCurrentVideoId] = useState(accountFaqCurrentSlide);
  const videoRefs = useRef<(HTMLDivElement | null)[]>([]);
  const sliderRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const videoIndex = videos.findIndex(
      (video) => video.id === accountFaqCurrentSlide
    );
    if (videoIndex !== -1 && videoRefs.current[videoIndex]) {
      videoRefs.current[videoIndex]?.scrollIntoView({ behavior: 'smooth' });
      // reset current slide
      dispatch(appActions.setAccountFaqCurrentSlide(''));
    }
  }, [currentVideoId]);

  const scrollSlider = (direction: 'left' | 'right') => {
    if (sliderRef.current) {
      const slideWidth =
        sliderRef.current.clientWidth / (window.innerWidth > 768 ? 2 : 1); // Adjust based on screen size
      const scrollAmount = window.innerWidth > 768 ? 2 * slideWidth : slideWidth; // Move by 1 slide on mobile and 2 slides for desktop

      sliderRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div>
      <div ref={sliderRef} className="slider">
        {videos.map((e, i) => (
          <div
            key={e.id}
            ref={(el) => (videoRefs.current[i] = el)}
            className="slide"
            data-cy={`video-${i}`}
          >
            <h4>{e.title}</h4>
            <div className="video">
              <ReactPlayer
                controls
                light
                width="100%"
                height="100%"
                url={e.url}
                data-cy={`video-player-${i}`}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="slider-actions">
        <ArrowBackIcon onClick={() => scrollSlider('left')} />
        <ArrowForwardIcon onClick={() => scrollSlider('right')} />
      </div>
    </div>
  );
}
