import { nanoid } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { forwardRef, memo, useEffect, useRef } from 'react';
import { useAppSelector } from '../../../../App/store';
import { getFoldersState } from '../../foldersSlice';
import DashboardFolderPlots from '../dashboard/DashboardFolderPlots';

import IndividualCircularLoader from '../../../loaders/components/individualCircularLoader/IndividualCircularLoader';
import FolderPlots from '../../../panels/components/leftPanelModal/menuTabs/prospection/folderPlots/FolderPlots';
import styles from './folder.module.scss';
import FolderHeader from './FolderHeader';

interface IFolderProps {
  isSubfolder?: boolean;
  isDashboard?: boolean;
  folder: IFolder;
  registerFolderRef?: (id: string, element: HTMLDivElement | null) => void;
}

const Folder = forwardRef<HTMLDivElement, IFolderProps>(
  ({ folder, isSubfolder, isDashboard, registerFolderRef }, ref) => {
    const { openedFolder, persistantOpenedFolder } = useAppSelector(getFoldersState);

    const elementRef = useRef<HTMLDivElement | null>(null);

    // Create a unique ID for this folder
    const folderId = isSubfolder
      ? `subfolder-${folder.idIri}`
      : `folder-${folder.idIri}`;

    // Register this element's ref with the parent component
    useEffect(() => {
      if (registerFolderRef && folder.idIri) {
        registerFolderRef(folderId, elementRef.current);
      }
    }, [registerFolderRef, folder.idIri, folderId]);

    if (!folder) {
      return <IndividualCircularLoader size={50} />;
    }

    return (
      <div
        className={styles.folder}
        data-cy="folder-item"
        data-folder-id={folderId}
        ref={(el) => {
          // Store in local ref
          elementRef.current = el;
          // Also pass to forwarded ref if provided
          if (typeof ref === 'function') {
            ref(el);
          } else if (ref) {
            ref.current = el;
          }
        }}
      >
        {/*  HEADER */}
        <FolderHeader
          folder={folder}
          isDashboard={isDashboard}
          deployedFolder={
            isDashboard
              ? openedFolder.folder
              : openedFolder.folder || persistantOpenedFolder.folder
          }
          deployedSubFolder={
            isDashboard
              ? openedFolder.subfolder
              : openedFolder.subfolder || persistantOpenedFolder.subfolder
          }
          isSubfolder={isSubfolder}
        />

        <div className={styles.folderContent}>
          {/*  display subfolders */}
          {!isSubfolder && folder.idIri === openedFolder.folder?.idIri && folder.subs
            ? [...folder.subs]?.map((f) => (
                <Folder
                  isSubfolder={true}
                  folder={f}
                  key={nanoid()}
                  isDashboard={isDashboard}
                  registerFolderRef={registerFolderRef}
                />
              ))
            : null}

          {/*  display folderPlots */}
          {(folder.idIri === openedFolder.folder?.idIri ||
            folder.idIri === openedFolder.subfolder?.idIri) &&
            (isDashboard ? (
              <DashboardFolderPlots
                folder={
                  isSubfolder
                    ? (openedFolder.subfolder as IFolder)
                    : (openedFolder.folder as IFolder)
                }
                color={folder.markerColor}
                isDashboard={isDashboard}
                isSub={isSubfolder}
              />
            ) : (
              <FolderPlots folder={folder} color={folder.markerColor} />
            ))}
        </div>
      </div>
    );
  }
);

const doRender = (p: Readonly<IFolderProps>, n: Readonly<IFolderProps>) => {
  return isEqual(p, n);
};

// Set the display name for the component
Folder.displayName = 'Folder';

export default memo(Folder, doRender);
