import { isEmpty } from 'lodash';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import IndividualCircularLoader from '../../../loaders/components/individualCircularLoader/IndividualCircularLoader';
import useSector from '../../hooks/useSector';

import { Button } from '@mui/material';
import { useCookies } from 'react-cookie';
import AutocompleteCustom from '../../../../shared/components/common/formComponents/AutocompleteCustom';
import { getAuthState } from '../../../auth/authSlice';
import { getCompanyState } from '../../../company/companySlice';
import {
  displayManagerActions,
  getDisplayManagerState,
} from '../../../displayManager/displayManagerSlice';
import { sectorsToEntitiesDisplay } from '../../../displayManager/utils/entitiesDisplayParsers';
import { DrawActionEnum } from '../../../map/plugins/drawPlugin/types/drawActionEnum';
import {
  getMapPluginsDrawState,
  mapPluginsActions,
} from '../../../map/plugins/mapPluginSlice';
import SectionHeader from '../../../panels/components/leftPanelModal/menuTabs/prospection/sectionHeader/SectionHeader';
import ProspectionContainer from '../../../panels/components/rightPanelModal/tabsContent/bodys/components/prospection/ProspectionContainer';
import useUsers from '../../../users/useUsers';
import { getSectorState } from '../../sectorSlice';
import sectorsFetchFilteredThunk from '../../services/thunks/sectorsFetchFilteredThunk';
import Sector from './Sector';
import styles from './sectorContainer.module.scss';

const SectorContainer = () => {
  const dispatch = useAppDispatch();
  const { usersForSelect } = useUsers({});
  const { companyId } = useAppSelector(getCompanyState);
  const { allSectorsDisplayed } = useAppSelector(getDisplayManagerState);
  const { action } = useAppSelector(getMapPluginsDrawState);
  const { userIdIri } = useAppSelector(getAuthState);
  const { deployedSector } = useAppSelector(getSectorState);
  const { filteredSectors, sectors } = useSector({ forceLoadFiltered: true });
  const cookieName = 'inputValueFilterSector' + companyId;
  const [cookies, setCookie, removeCookie] = useCookies([cookieName]);
  const [filter, setFilter] = useState<IFilterSector>({
    user: cookies[cookieName]?.user ?? [],
  });
  // Create a ref to store references to sector elements
  const sectorsRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleChange = (data: IFilterSector) => {
    setFilter(data);
  };

  const handleDisplayAllSectors = (e: ChangeEvent<HTMLInputElement>) => {
    if (filteredSectors) {
      if (e.target.checked) {
        dispatch(
          displayManagerActions.allSectorsAdd(
            sectorsToEntitiesDisplay(filteredSectors, userIdIri)
          )
        );
      } else {
        dispatch(displayManagerActions.entitiesRemoveByType('sector'));
      }
    }
  };

  const handleCreateSector = (): void => {
    if (!action) {
      dispatch(mapPluginsActions.drawStart(DrawActionEnum.DRAW_SECTOR));
    }
  };

  const handleFilterSearch = () => {
    setCookie(cookieName, { ...filter });
    dispatch(sectorsFetchFilteredThunk({}));
  };

  const handleClearSearch = () => {
    setFilter({
      user: [],
    });
    removeCookie(cookieName);
    dispatch(sectorsFetchFilteredThunk({}));
  };

  // useEffect hook to handle side effects
  useEffect(() => {
    // Check if filteredSectors is defined, deployedSector has an id, and the ref exists
    if (
      filteredSectors && // Ensure filteredSectors is not null or undefined
      deployedSector?.id && // Check if deployedSector has a valid id
      sectorsRefs.current[deployedSector?.id] // Verify the ref for the sector id exists
    ) {
      // Scroll the folder element into view with smooth behavior
      sectorsRefs.current[deployedSector?.id]?.scrollIntoView({
        behavior: 'smooth', // Smooth scrolling effect
      });
    }
  }, [deployedSector]); // Dependency array: effect runs when sectors changes

  return (
    <div className={styles.sectorSection}>
      <SectionHeader
        checkAll={allSectorsDisplayed}
        handleCheckAllChange={handleDisplayAllSectors}
        handleAdd={handleCreateSector}
        type="secteur"
      />
      <ProspectionContainer title="Filtre Secteurs">
        <div className={styles.filter}>
          <AutocompleteCustom
            options={usersForSelect}
            value={filter.user}
            placeholder="Filtrer par responsable"
            multiple
            onChange={(value: any) => {
              handleChange({ ...filter, user: value as ISelectItem[] });
            }}
          />
          <div className={styles.buttons}>
            <Button onClick={handleFilterSearch}>Appliquer</Button>
            <Button onClick={handleClearSearch}>Effacer</Button>
          </div>
        </div>
      </ProspectionContainer>

      <div className={styles.sectorContainer}>
        {!filteredSectors ? (
          <IndividualCircularLoader size={100} />
        ) : isEmpty(filteredSectors) ? (
          <p>Aucun secteur</p>
        ) : (
          filteredSectors.map((sector) => (
            <div ref={(el) => (sectorsRefs.current[sector.id] = el)} key={sector.id}>
              <Sector sector={sector} isSubsector={false} />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SectorContainer;
