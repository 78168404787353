import { useAppDispatch } from '../../../App/store';
import { foldersActions } from '../../../features/folders/foldersSlice';
import { fetchFolderPlotStudiesThunk } from '../../../features/folders/services/thunks/foldersThunk';

import { sectorActions } from '../../../features/sectors/sectorSlice';
import ExpandIcon from '../icons/ExpandIcon';
import styles from './itemActions.module.scss';

interface IArrowIconProps<T> {
  isOpen: boolean;
  isDashboard?: boolean;
  type: string;
  item: IFolderSector<T>;
}
function ArrowIcon<T>({
  type,
  isOpen,
  item,
  isDashboard = false,
}: IArrowIconProps<T>) {
  const dispatch = useAppDispatch();

  const handleClick = (): void => {
    if (type === 'folder') {
      if (!isOpen) {
        dispatch(
          fetchFolderPlotStudiesThunk({
            folder: item as IFolder,
            isDashboard,
          })
        );
      }
      dispatch(
        foldersActions.openedFolderSet({ folder: item as IFolder, isDashboard })
      );
    } else {
      if (!isOpen) {
        dispatch(sectorActions.deployedSectorSet(item as ISector));
      } else {
        dispatch(sectorActions.deployedSectorReset());
      }
    }
  };
  return (
    <ExpandIcon
      className={[styles.dropDownIcon, isOpen ? styles.close : ''].join(' ')}
      onClick={handleClick}
    />
  );
}

export default ArrowIcon;
