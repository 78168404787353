export enum ConnectionWorkFlowEnum {
  IDLE = 'IDLE',
  INIT_CONNECTION = 'INIT_CONNECTION',
  SET_TOKEN_IN_STORE = 'SET_TOKEN_IN_STORE',
  TOKEN_ERROR = 'TOKEN_ERROR',
  START_COMPANY_PROCESS = 'START_COMPANY_PROCESS',
  START_USER_PROCESS = 'START_USER_PROCESS',
  START_FETCH_ANNOUNCEMENT_PROCESS = 'START_FETCH_ANNOUNCEMENT_PROCESS',
  START_FETCH_PLOT_PROCESS = 'START_FETCH_PLOT_PROCESS',
  START_FETCH_PDF_DATA_PROCESS = 'START_FETCH_PDF_DATA_PROCESS',
  END_CONNECTION_PROCESS = 'END_CONNECTION_PROCESS',
  REDIRECT_TO_DEFINED_URL = 'REDIRECT_TO_DEFINED_URL',
  CLOSE_CONNECTION_MODAL = 'CLOSE_CONNECTION_MODAL',
  ERROR_CONNEXION_INIT = 'ERROR_CONNEXION_INIT',
  ERROR_REFRESH_TOKEN_FAIL = 'ERROR_REFRESH_TOKEN_FAIL',
}

export enum UserProcessStepEnum {
  IDLE = 'IDLE',
  PROCESS_INIT = 'PROCESS_INIT',
  DATAS_PENDING = 'DATAS_PENDING',
  DATAS_FULFILLED = 'DATAS_FULFILLED',
  DATAS_REJECTED = 'DATAS_REJECTED',
  END_PROCESS = 'END_PROCESS',
  ERROR_TOKEN = 'ERROR_TOKEN',
  ERROR_INACTIVE_USER = 'ERROR_INACTIVE_USER',
  ERROR_NOT_FOUND = 'ERROR_NOT_FOUND',
  ERROR_NOT_AUTHORIZED = 'ERROR_NOT_AUTHORIZED',
  ERROR_FORBIDDEN = 'ERROR_FORBIDDEN',
  ERROR_SERVER = 'ERROR_SERVER',
}

export enum CompanyProcessStepEnum {
  IDLE,
  PROCESS_INIT,
  COMPANY_CHOICE,
  COMPANY_CHOSEN,
  COMPANY_LOADED,
  END_PROCESS,
  ERROR_TOKEN,
  ERROR_NO_COMPANY,
  ERROR_NO_COMPANY_IN_STORE,
  ERROR_NOT_FOUND,
  ERROR_NOT_AUTHORIZED,
  ERROR_FORBIDDEN,
  ERROR_SERVER,
}

export enum AnnouncementProcessStepEnum {
  IDLE,
  PROCESS_INIT,
  DATAS_PENDING,
  DATAS_FULFILLED,
  END_PROCESS,
}

export enum FetchPlotProcessStepEnum {
  IDLE,
  PROCESS_INIT,
  DATAS_PENDING,
  DATAS_FULFILLED,
  DATAS_REJECTED,
  END_PROCESS,
  ERROR_FETCH_PLOT_PARAM,
  ERROR_INACTIVE_USER,
  ERROR_NOT_FOUND,
  ERROR_NOT_AUTHORIZED,
  ERROR_FORBIDDEN,
  ERROR_SERVER,
}

export enum PdfDatasProcessStepEnum {
  IDLE,
  PROCESS_INIT,
  DATAS_PENDING,
  DATAS_FULFILLED,
  DATAS_REJECTED,
  END_PROCESS,
}
