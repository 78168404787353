import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import styles from '../advancedPlotSearchContainer.module.scss';
import { radiobuttonsPlotType } from '../formSchema';
import DrawCheckbox from './DrawCheckbox';
import PlotCalculationInputGroup from './PlotCalculationInputGroup';

const PlotCalculation = (): React.ReactElement => {
  const { control } = useFormContext();
  return (
    <div className={styles.plotCalculationContainer}>
      <div className={styles.plotCalculationContent}>
        <DrawCheckbox />

        <div className={styles.radioContainer}>
          <Controller
            name="plotType"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup onChange={onChange} row value={value}>
                {radiobuttonsPlotType.map((elmt, i) => (
                  <FormControlLabel
                    className={styles.radioLabel}
                    key={elmt.value}
                    value={elmt.value}
                    control={<Radio data-cy={`plot-type-radio-option-${i}`} />}
                    label={elmt.label}
                    labelPlacement="end"
                    checked={value === elmt.value}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </div>

        <PlotCalculationInputGroup
          min={'minPlot'}
          max={'maxPlot'}
          placeHolder="Surface en m²"
        />

        <PlotCalculationInputGroup
          min={'minGround'}
          max={'maxGround'}
          placeHolder="Surface en m²"
        />
      </div>
    </div>
  );
};

export default memo(PlotCalculation);
