import * as Yup from 'yup';

export interface IPasswordTabForm {
  newPassword: string;
  verifyPassword: string;
}

export const initialPasswordForm: IPasswordTabForm = {
  newPassword: '',
  verifyPassword: '',
};

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-_&!?#*$])[a-zA-Z\d-_&!?#*$]{8,}$/;

export const passwordValidationSchema = Yup.object({
  newPassword: Yup.string().required('Mot de passe requis').matches(passwordRegex),
  verifyPassword: Yup.string()
    .default('')
    .test('verify-password-validation', function (value) {
      const { newPassword } = this.parent;

      // Check if `password` is valid
      const isPasswordValid = Yup.string()
        .matches(passwordRegex)
        .isValidSync(newPassword);

      // is `password` is invalid, ignore all checks for `verifyPassword`
      if (!isPasswordValid) {
        return true;
      }

      // If `password` is valid, check if `verifyPassword` is good
      if (!value) {
        return this.createError({ message: 'Confirmation du mot de passe requise' });
      }

      if (value !== newPassword) {
        return this.createError({
          message: 'Les mots de passe ne correspondent pas',
        });
      }

      return true;
    }),
});
