import { yupResolver } from '@hookform/resolvers/yup';
import { cloneDeep } from 'lodash';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { PDF_MAX_COMMENT_CHARACTERS } from '../../../shared/constants';
import getBiggestPropertyObjectFromArray from '../../../shared/utils/getBiggestPropertyObjectFromArray';
import { getAuthState } from '../../auth/authSlice';
import useCompany from '../../company/hooks/useCompany';
import ProgressBarCustom from '../../loaders/components/progressBarCustom/ProgressBarCustom';
import { getMapState } from '../../map/mapSlice';
import { getPdfState, pdfActions } from '../pdfSlice';
import PdfRightPanelModalWrapper from '../shared/components/PdfRightPanelModalWrapper';
import GoodDetailsSection from '../shared/components/rightPanelModalExternalPdfErrial/GoodDetailsSection';
import OwnerAndAddressSection from '../shared/components/rightPanelModalExternalPdfErrial/OwnerAndAddressSection';
import ProInfosSection from '../shared/components/rightPanelModalExternalPdfErrial/ProInfosSection';
import TextAreaInputFormPdfErrial from '../shared/components/rightPanelModalExternalPdfErrial/TextAreaInputFormPdfErrial';
import styles from '../shared/styles/pdfRightPanelModal.module.scss';
import { geolocDataCity, geolocDataStreet } from '../shared/utils/utils';
import OrpiErrialButton from './OrpiErrialButton';
import {
  classicFormSbDatasParser,
  initialPdfErrialForm,
  validationSchemaPdfErrialForm,
} from './utils';

interface IProps {
  type: PdfType;
}

const PdfRightPanelModal = ({ type }: IProps) => {
  // **** redux selector and dispatch
  const { user } = useAppSelector(getAuthState);
  const { geolocDatas } = useAppSelector(getMapState);
  const {
    sweepbrightData,
    multiPlotsPdf,
    isMultiPlotSelectorPdf,
    clicked,
    pdfFormData,
  } = useAppSelector(getPdfState);
  const dispatch = useAppDispatch();

  // **** hooks ****
  const { getCompanyName } = useCompany();
  const hookFormMethods = useForm<IExternalPdfErrialForm>({
    defaultValues: pdfFormData
      ? (pdfFormData as IExternalPdf)
      : initialPdfErrialForm(getCompanyName, user),
    resolver: yupResolver(validationSchemaPdfErrialForm),
  });

  // **** constants ****
  const {
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
    trigger,
  } = hookFormMethods;

  // **** handles ****

  const submitForm = (data: IExternalPdfErrialForm): void => {
    dispatch(pdfActions.setPdfFormData(cloneDeep(data)));
  };

  // **** useEffects ****
  useEffect(() => {
    trigger();
  }, [isValid]);

  useEffect(() => {
    classicFormSbDatasParser(sweepbrightData.result, geolocDatas, hookFormMethods);
  }, [sweepbrightData, geolocDatas?.houseNumber, geolocDatas?.streetName]);

  useEffect(() => {
    if (
      isMultiPlotSelectorPdf &&
      multiPlotsPdf.result &&
      (multiPlotsPdf.result?.length ?? 0) > 0
    ) {
      const plot = getBiggestPropertyObjectFromArray(
        multiPlotsPdf.result,
        'theoricCapacity'
      );
      setValue(
        'addressCity',
        plot.city
          ? geolocDataCity(geolocDatas)
          : sweepbrightData.result?.property?.location?.address
            ? geolocDataCity(sweepbrightData.result?.property.location.address)
            : ''
      );
      setValue(
        'addressStreet',
        plot.streetName
          ? geolocDataStreet(plot)
          : sweepbrightData.result?.property?.location?.address
            ? geolocDataStreet(sweepbrightData.result?.property.location.address)
            : ''
      );
    }
  }, [multiPlotsPdf.result]);
  useEffect(() => {
    return () => {
      dispatch(pdfActions.setPdfFormData(cloneDeep(getValues())));
    };
  }, []);

  return (
    <PdfRightPanelModalWrapper>
      <FormProvider {...hookFormMethods}>
        <form
          onSubmit={handleSubmit(submitForm)}
          autoComplete="off"
          className={`${styles.rightPanelModalBody} ${clicked ? styles.hidden : ''}`}
        >
          <div className={styles.plotForm}>
            <div>
              <h3>Détails du bien étudié</h3>
              <div className={styles.formSection}>
                <GoodDetailsSection type={type} />
                <OwnerAndAddressSection />
                <div
                  className={`${styles.commentContainer} ${errors.description?.message ? styles.error : ''}`}
                >
                  <p>
                    Commentaire rédigé par l’utilisateur professionnel{' '}
                    <span>({PDF_MAX_COMMENT_CHARACTERS} caractères max)</span>
                  </p>
                  <TextAreaInputFormPdfErrial
                    name="description"
                    rowsMax={6}
                    rowsMin={3}
                    placeholder="Commentaire ..."
                    className={errors.description?.message ? styles.error : ''}
                  />
                </div>
              </div>
            </div>

            <ProInfosSection />
          </div>
          <div className={styles.buttonContainer}>
            <OrpiErrialButton disabled={!isValid} type={type} />
          </div>
        </form>
      </FormProvider>

      {clicked && (
        <div className={styles.progressBarContainer}>
          <ProgressBarCustom height={25} totalStep={5} widthPercent={80} />
        </div>
      )}
    </PdfRightPanelModalWrapper>
  );
};

export default PdfRightPanelModal;
