import { Tooltip } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from '../../../../App/store';
import SelectFormCustom from '../../../../shared/components/common/formComponents/SelectFormCustom';
import { getPluState } from '../../../plu/pluSlice';
import styles from './advancedPlotSearchContainer.module.scss';

interface IZonageProps {
  city: string | null;
  postalCode: string | null;
  inseeCode: string | null;
}

const Zonage = ({
  city,
  postalCode,
  inseeCode,
}: IZonageProps): React.ReactElement => {
  const { control, watch, setValue } = useFormContext();
  const { zones, zoneChilds } = useAppSelector(getPluState);
  const [selectedZoneChilds, setSelectedZoneChilds] = useState<ISelectItem[]>([]);

  const isEnabled = Boolean(city && postalCode);

  useEffect(() => {
    if (inseeCode) {
      if (zoneChilds) {
        setSelectedZoneChilds(
          zoneChilds[watch('zone')]?.map((z) => ({ value: z, display: z })) ?? []
        );
      }
    }
  }, [inseeCode]);

  const handleZoneChange = () => {
    if (!watch('zone')) {
      setValue('zoneChild', '');
      setValue('zone', '');
    }
    if (zoneChilds) {
      setSelectedZoneChilds(
        zoneChilds[watch('zone')]?.map((z) => ({ value: z, display: z })) ?? []
      );
    }
  };

  return (
    <div className={styles.zoneContainer}>
      <div className={styles.zoneContent}>
        <div className={styles.field}>
          <SelectFormCustom
            items={zones?.map((z) => ({ value: z, display: z })) ?? []}
            name="zone"
            displayEmpty
            noValue="Toute zone"
            onChange={handleZoneChange}
            noChoiceDisabled={false}
            dataCy="zone"
          />
        </div>

        <Tooltip
          title={
            !isEnabled
              ? 'Saisir une ville'
              : watch('zone')
                ? 'aucune zone enfant pour cette zone'
                : 'Choisir une zone'
          }
          placement="top"
          disableHoverListener={Boolean(isEnabled && zoneChilds)}
        >
          <div className={styles.field}>
            <SelectFormCustom
              items={selectedZoneChilds}
              name="zoneChild"
              displayEmpty
              noValue="Toute zone enfant"
              noChoiceDisabled={false}
              dataCy="zone-child"
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default memo(Zonage);
