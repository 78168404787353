import { Checkbox, FormControlLabel } from '@mui/material';
import { isEmpty } from 'lodash';
import { ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../App/store';
import {
  displayManagerActions,
  getDisplayManagerState,
} from '../../../../../../features/displayManager/displayManagerSlice';
import {
  getStudyState,
  studyActions,
} from '../../../../../../features/study/slices/studySlice';
import { fetchFolderAndSubsPlotStudiesForDisplayThunk } from '../../../../../displayManager/services/thunks/fetchFolderAndSubsPlotStudiesForDisplayThunk';
import { getIdIriTabFromFolderAndSubs } from '../../../../../displayManager/utils';
import useFolder from '../../../../../folders/hooks/useFolder';

export default function FolderHeader() {
  const dispatch = useAppDispatch();
  const { study, studyPlotStudies, studyPlotStudiesDisplay } =
    useAppSelector(getStudyState);
  const { folderList } = useFolder({});
  const { displayedFolders } = useAppSelector(getDisplayManagerState);
  const hasNoPlots = !studyPlotStudies.result || isEmpty(studyPlotStudies.result);
  const folder = folderList?.find((folder) => study.result?.idIri === folder.idIri);
  const checked =
    studyPlotStudiesDisplay ||
    Boolean(study.result && displayedFolders.includes(study.result?.idIri));

  const handlePlotDisplay = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.checked;
    if (folder)
      if (value) {
        dispatch(
          fetchFolderAndSubsPlotStudiesForDisplayThunk({
            folder,
          })
        );
      } else {
        dispatch(
          displayManagerActions.entitiesRemoveByParent(
            getIdIriTabFromFolderAndSubs(folder)
          )
        );
      }
    dispatch(studyActions.studyPlotStudiesDisplay(value));
  };

  return (
    <div className="header-central-bloc-plot is-folder">
      <p className="title">{study.result && (study.result as IFolderStudy).name}</p>

      <FormControlLabel
        value="Afficher les parcelles sur la carte"
        control={
          <Checkbox
            className="display-plot"
            onChange={handlePlotDisplay}
            checked={checked}
            disabled={hasNoPlots}
          />
        }
        label={
          hasNoPlots
            ? 'Aucune parcelle à afficher dans ce dossier (dossier vide)'
            : 'Afficher les parcelles sur la carte'
        }
        labelPlacement="end"
      />
    </div>
  );
}
