import { createAsyncThunk, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { RootState } from '../../../../App/store';
import { InternalError } from '../../../../services/axiosFiles/axiosUtils';
import {
  deleteEntity,
  fetchDatas,
  postEntity,
  updateEntity,
} from '../../../../services/axiosFiles/genericCrud';
import { modalsActions } from '../../../modals/modalsSlice';

import { fetchPluZoneFromPlotListSlice } from '../../../../shared/services/fetchPluZoneFromPlotList';
import { displayManagerActions } from '../../../displayManager/displayManagerSlice';
import { getParentAndChildIdIriTab } from '../../../displayManager/utils';
import { loadersActions } from '../../../loaders/loaderSlice';
import { checkAndUpdatePlotStudyDatas } from '../../../study/services/checkAndUpdatePlotStudyDatas';
import { folderParser, foldersParser } from '../../folderParser';
import { foldersActions } from '../../foldersSlice';
import fetchFolderFilterSortQuery from '../../utils/fetchFolderFilterSortQuery';
import { handleExportExcel } from '../../utils/handleExportExcel';
import { fetchFolderPlotStudies } from '../fetchFolderPlotStudies';
import { fetchFolderSynthese } from '../fetchFolderSynthese';

// *********************************************************
// ******************** FETCH FOLDERS **********************
// *********************************************************
const fetchFoldersExtended = (
  folders: any[],
  dFolder: IFolder | null,
  dSubfolder: IFolder | null,
  isDashboard: boolean,
  dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
) => {
  const findFolder = (folders: any[], folder?: IFolder | null) =>
    folders?.find((f: any) => f['@id'] === folder?.idIri);

  const dfExists = findFolder(folders, dFolder);
  const dsfExists = findFolder(folders, dSubfolder);

  // if deployed folder, load plot studies for persistence display
  if (dFolder && dfExists) {
    dispatch(
      fetchFolderPlotStudiesThunk({
        folder: dFolder,
        isDashboard,
      })
    );
  }

  // if deployed subfolder, load plot studies for persistence display
  if (dSubfolder && dsfExists) {
    dispatch(
      fetchFolderPlotStudiesThunk({
        folder: dSubfolder,
        isDashboard,
      })
    );
  }
};

export const fetchFoldersThunk = createAsyncThunk(
  'folders/fetchFoldersThunk',
  async (
    {
      companyIdIri,
      isDashboard,
    }: { companyIdIri: string | null; isDashboard: boolean },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const state = getState() as RootState;
      const users = state.users.users;
      const { persistantOpenedFolder, openedFolder } = state.folders;
      const { companyId } = state.company;

      if (companyIdIri) {
        const endpoint = companyIdIri + '/folders';
        const response = await fetchDatas(`${endpoint}`);

        if (users && companyId) {
          const parsedResponse = foldersParser(
            response['hydra:member'],
            users,
            companyId
          );

          // fetch plot studies folders of opened folder end subfolder if exists
          fetchFoldersExtended(
            response['hydra:member'],
            persistantOpenedFolder.folder,
            persistantOpenedFolder.subfolder,
            isDashboard,
            dispatch
          );

          return parsedResponse;
        } else {
          return rejectWithValue({
            status: 400,
            message: 'need users and companyId for parse data',
          });
        }
      } else {
        return rejectWithValue({ status: 0, message: 'companyIdIri param missing' });
      }
    } catch (error) {
      return rejectWithValue(InternalError(error));
    }
  }
);

export const fetchFoldersFilteredThunk = createAsyncThunk(
  'folders/fetchFoldersFilteredThunk',
  async (
    { companyIdIri }: { companyIdIri: string | null; isDashboard: boolean },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const state = getState() as RootState;
      const users = state.users.users;
      const { persistantOpenedFolder } = state.folders;
      const { companyId } = state.company;

      if (companyIdIri && companyId) {
        const { customQuery, query } = fetchFolderFilterSortQuery(companyId);

        const endpoint = companyIdIri + '/folders';
        const response = await fetchDatas(`${endpoint}${customQuery}`, query);

        if (users) {
          const parsedResponse = foldersParser(
            response['hydra:member'],
            users,
            companyId,
            true
          );

          fetchFoldersExtended(
            response['hydra:member'],
            persistantOpenedFolder.folder,
            persistantOpenedFolder.subfolder,
            false,
            dispatch
          );

          return parsedResponse;
        } else {
          return rejectWithValue({
            status: 400,
            message: 'need users and companyId for parse data',
          });
        }
      } else {
        throw new Error('companyIdIri param missing');
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

// *********************************************************
// ******* FETCH FOLDER PLOT STUDIES BY FOLDER ID *********
// *********************************************************
export const fetchFolderPlotStudiesThunk = createAsyncThunk(
  'folders/fetchFolderPlotStudiesThunk',
  async (
    params: {
      folder: IFolder | null;
      forDisplay?: boolean;
      isDashboard?: boolean;
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;
      const { users } = state.users;
      const { plotStudyStatuses } = state.app;
      const { companyId } = state.company;

      if (params.folder && companyId) {
        const { customQuery, query } = fetchFolderFilterSortQuery(companyId);
        // if plotStudies allready loaded and display = true => no load
        if (!isEmpty(params.folder.plotStudies) && params.forDisplay) {
          dispatch(foldersActions.singleFolderOnMapShow(params.folder));
          return null;
        }

        const body = params.isDashboard
          ? {
              folder: params.folder,
              users: users ?? [],
              config: { params: { includeContacts: true } },
              statuses: plotStudyStatuses.result ?? [],
            }
          : {
              folder: params.folder,
              config: { params: query },
              users: users ?? [],
              statuses: plotStudyStatuses.result ?? [],
              customQuery: customQuery,
            };

        const plotStudies = await fetchFolderPlotStudies(body);

        return {
          folderIdIri: params.folder?.idIri as string,
          isSub: Boolean(params.folder?.parent),
          plotStudies: plotStudies,
          forDisplay: params.forDisplay ?? false,
        };
      } else {
        return rejectWithValue({
          status: 400,
          message: 'need folder and companyId for parse data',
        });
      }
    } catch (error) {
      return rejectWithValue(InternalError(error));
    }
  }
);

// *********************************************************
// ******************** CREATE FOLDER **********************
// *********************************************************
export const folderCreateThunk = createAsyncThunk(
  'folders/folderCreateThunk',
  async (
    params: {
      name: string;
      markerColor: string;
      parentFolder: string | null;
      company: string;
      callback: () => void;
    },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const state = getState() as RootState;
      const users = state.users.users;
      const userIdIri = state.auth.userIdIri;
      const { companyId, companyIdIri } = state.company;
      const { name, markerColor, company } = params;

      if (name && markerColor && company && userIdIri) {
        const result = await postEntity({
          endpoint: '/folders',
          body: { ...params, responsable: userIdIri },
        });

        if (users && companyId) {
          const parsedFolder = folderParser(result, users, companyId);
          params.callback();
          dispatch(fetchFoldersFilteredThunk({ companyIdIri, isDashboard: false }));
          return parsedFolder;
        } else {
          return rejectWithValue({
            status: 400,
            message: 'need users and companyId for parse data',
          });
        }
      } else {
        return rejectWithValue({
          status: 0,
          message: 'userIdIri or folder param missing',
        });
      }
    } catch (error) {
      return rejectWithValue(InternalError(error));
    }
  }
);

// *********************************************************
// ******************** UPDATE FOLDER **********************
// *********************************************************
export const folderUpdateThunk = createAsyncThunk(
  'folders/folderUpdateThunk',
  async (
    params: {
      folderIdIri: string;
      folder: Pick<IFolder, 'name' | 'markerColor' | 'indexId'>;
      callback: () => void;
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;
      const users = state.users.users;
      const { companyId } = state.company;
      const { folderIdIri, folder } = params;

      if (folderIdIri && folder) {
        const promise = updateEntity({
          idIri: params.folderIdIri,
          body: { ...params.folder },
        });

        return promise.then(
          (response) => {
            if (users && companyId) {
              const parsedFolder = folderParser(response, users, companyId);
              params.callback();
              return parsedFolder;
            } else {
              return rejectWithValue({
                status: 400,
                message: 'need users and companyId for parse data',
              });
            }
          },
          (err) => {
            return rejectWithValue(err);
          }
        );
      } else {
        return rejectWithValue({
          status: 0,
          message: 'userIdIri or folder param missing',
        });
      }
    } catch (error) {
      return rejectWithValue(InternalError(error));
    }
  }
);

// *********************************************************
// ******************** DELETE FOLDER **********************
// *********************************************************
export const folderDeleteThunk = createAsyncThunk(
  'folders/folderDeleteThunk',
  async (
    params: {
      folder: IFolder | null;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      if (params.folder?.idIri) {
        dispatch(loadersActions.loaderShow());
        await deleteEntity(params.folder.idIri);

        //remove plot studies folder from entities display
        dispatch(
          displayManagerActions.entitiesRemoveByParent(
            getParentAndChildIdIriTab(params.folder)
          )
        );
        dispatch(foldersActions.folderForActionReset());
        dispatch(modalsActions.folderDelete(false));
        dispatch(loadersActions.loaderHide());
        return params.folder;
      } else {
        dispatch(loadersActions.loaderHide());
        return rejectWithValue({
          status: 0,
          message: 'folderIdIri param missing',
        });
      }
    } catch (error) {
      dispatch(loadersActions.loaderHide());
      return rejectWithValue(error);
    }
  }
);

// *********************************************************
// **************** FETCH FOLDER SYNTHESE ******************
// *********************************************************
export const fetchSyntheseFolderThunk = createAsyncThunk(
  'folders/fetchSyntheseFolderThunk',
  async (params: { plotStudies: PlotStudies }) => {
    try {
      if (params.plotStudies) {
        const synthese = fetchFolderSynthese(params.plotStudies);

        return synthese;
      } else {
        return Promise.reject({
          status: 0,
          message: 'folder param missing',
        });
      }
    } catch (error) {
      return Promise.reject(InternalError(error));
    }
  }
);

// *********************************************************
// ********* EXPORT PLOTSTUDIES FOLDER TO EXCEL ***********
// *********************************************************
export const fetchFolderPlotStudiesForExcel = createAsyncThunk(
  'folders/fetchFolderPlotStudiesForExcel',
  async (params: { folder: IFolder }, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const { users } = state.users;
      const { plotStudyStatuses } = state.app;

      if (params.folder) {
        const updatedPlotStudies: PlotStudies = [];
        const plotStudies = await fetchFolderPlotStudies({
          folder: params.folder,
          users: users ?? [],
          statuses: plotStudyStatuses.result ?? [],
        });

        // fetch actual zones from python
        const pluZones = await fetchPluZoneFromPlotListSlice(plotStudies);

        for (const ps of plotStudies) {
          const pz = pluZones[ps.fullPlotId]?.plu_zone;
          if (ps.zone !== pz.zone || ps.zoneChild !== pz.zone_child) {
            ps.zone = pz.zone;
            ps.zoneChild = pz.zone_child;
          }

          const newPs = await checkAndUpdatePlotStudyDatas({
            ps,
            zones: { zone: pz.zone, zoneChild: pz.zone_child },
          });
          updatedPlotStudies.push(newPs);
        }

        handleExportExcel(params.folder.name, updatedPlotStudies);
      } else {
        return rejectWithValue({ status: 0, message: 'folder param missing' });
      }
    } catch (error) {
      return rejectWithValue(InternalError(error));
    }
  }
);
