import { useFormContext } from 'react-hook-form';
import AreaIconSvg from '../../../../../assets/images/orpiPdf/areaIcon.svg';
import FloorCountIconSvg from '../../../../../assets/images/orpiPdf/floorCountIcon.svg';
import GoodTypoIconSvg from '../../../../../assets/images/orpiPdf/goodTypoIcon.svg';
import RoomCountIconSvg from '../../../../../assets/images/orpiPdf/roomCountIcon.svg';
import styles from '../../styles/pdfRightPanelModal.module.scss';
import AreaTextInputForPDF from '../form/AreaTextInputForPDF';
import NumberInputFormPdfErrialCustom from './NumberInputFormPdfErrialCustom';
import SelectInputFormPdfErrialCustom from './SelectInputFormPdfErrialCustom';

export const typologyItems = [
  { value: 'apartment', display: 'Appartement' },
  { value: 'house', display: 'Maison' },
  { value: 'building', display: 'Immeuble' },
  { value: 'field', display: 'Terrain' },
  { value: 'others', display: 'Autres' },
];

function GoodDetailsSection({ type }: { type: PdfType }) {
  // ***** HOOKS *****
  const {
    control,
    formState: { errors },
  } = useFormContext();

  // ***** CONSTANTS *****
  const isLandPdfType = ['ORPI_LAND', 'URBANEASE_LAND'].includes(type);

  return (
    <>
      <div className={styles.rowIcon}>
        <img src={GoodTypoIconSvg} alt="good typo icon" />
        <div className={styles.rowInput}>
          <p>Typologie du bien</p>
          <SelectInputFormPdfErrialCustom
            control={control}
            name="propertyType"
            noValue="Choisir une typologie"
            displayEmpty
            disabled={isLandPdfType}
            items={typologyItems}
            error={Boolean(errors.propertyType)}
            errorMsg={errors.propertyType?.message as string}
          />
        </div>
      </div>

      {!isLandPdfType && (
        <>
          <div className={styles.rowIcon}>
            <img src={RoomCountIconSvg} alt="room count icon" />
            <div className={styles.rowInput}>
              <p>Nombre de pièces</p>
              <NumberInputFormPdfErrialCustom
                name="numberOfRooms"
                error={Boolean(errors.numberOfRooms)}
                errorMsg={errors.numberOfRooms?.message as string}
              />
            </div>
          </div>
          <div className={styles.rowIcon}>
            <img src={FloorCountIconSvg} alt="floor count icon" />
            <div className={styles.rowInput}>
              <p>Nombre de niveaux</p>
              <NumberInputFormPdfErrialCustom
                name="floorNumber"
                error={Boolean(errors.floorNumber)}
                errorMsg={errors.floorNumber?.message as string}
              />
            </div>
          </div>
          <div className={styles.rowIcon}>
            <img src={AreaIconSvg} alt="area icon" />
            <div className={styles.rowInput}>
              <p>Surface habitable</p>
              <NumberInputFormPdfErrialCustom
                name="livingArea"
                error={Boolean(errors.livingArea)}
                errorMsg={errors.livingArea?.message as string}
              />
            </div>
          </div>
        </>
      )}

      {/* area component for onBlur event and confirmation change popup */}
      <AreaTextInputForPDF type={type} />
    </>
  );
}

export default GoodDetailsSection;
