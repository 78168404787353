import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import {
  fetchFolderPlotStudiesThunk,
  fetchFoldersFilteredThunk,
  fetchFoldersThunk,
  fetchSyntheseFolderThunk,
  folderCreateThunk,
  folderDeleteThunk,
  folderUpdateThunk,
} from './services/thunks/foldersThunk';

const initialState: FolderState = {
  folders: { apiStatus: APIStatus.IDLE, result: null },
  filteredFolders: { apiStatus: APIStatus.IDLE, result: null },
  folderForAction: null,
  folderRightPanelDatas: { apiStatus: APIStatus.IDLE, result: null },
  subFolderParent: null,
  openedFolder: { folder: null, subfolder: null },
  persistantOpenedFolder: { folder: null, subfolder: null },
  totalFolders: 0,
  totalFolderPlotStudies: 0,
};

const foldersSlice = createSlice({
  name: 'folders',
  initialState,
  reducers: {
    folderRightPanelDatasReset: (state) => {
      state.folderRightPanelDatas = { apiStatus: APIStatus.IDLE, result: null };
    },
    openedFolderSet: (
      state,
      action: PayloadAction<{ folder: IFolder; isDashboard: boolean }>
    ) => {
      const { folder, isDashboard } = action.payload;

      if (!folder.parent) {
        // Handling parent folder
        if (state.openedFolder.folder?.idIri === folder.idIri) {
          // If the folder is already opened, reset the opened folder and subfolder
          state.openedFolder = { folder: null, subfolder: null };

          if (!isDashboard) {
            state.persistantOpenedFolder = { folder: null, subfolder: null };
          }
        } else {
          // Open a new folder and reset subfolder
          state.openedFolder = { folder, subfolder: null };

          if (!isDashboard) {
            state.persistantOpenedFolder = { folder, subfolder: null };
          }
        }
      } else {
        // Handling subfolder
        if (state.openedFolder.folder?.idIri === folder.parent) {
          // Ensure subfolder belongs to the opened parent folder
          if (state.openedFolder.subfolder?.idIri === folder.idIri) {
            // If subfolder is already opened, close it
            state.openedFolder.subfolder = null;

            if (!isDashboard) {
              state.persistantOpenedFolder.subfolder = null;
            }
          } else {
            // Open a new subfolder
            state.openedFolder.subfolder = folder;

            if (!isDashboard) {
              state.persistantOpenedFolder.subfolder = folder;
            }
          }
        }
      }
    },

    persistantToOpenedFolderSet: (state) => {
      state.openedFolder = state.persistantOpenedFolder;
    },
    openedFolderReset: (state) => {
      state.openedFolder = { folder: null, subfolder: null };
    },
    openedSubfolderReset: (state) => {
      state.openedFolder.subfolder = null;
    },
    persistantFolderReset: (state) => {
      state.persistantOpenedFolder = { folder: null, subfolder: null };
    },
    persistantSubfolderReset: (state) => {
      state.persistantOpenedFolder.subfolder = null;
    },
    folderForActionSet: (state, action: PayloadAction<IFolder>) => {
      state.folderForAction = action.payload;
    },
    folderForActionReset: (state) => {
      state.folderForAction = null;
    },
    subFolderParentSet: (state, action: PayloadAction<string>) => {
      state.subFolderParent = action.payload;
    },
    subFolderParentReset: (state) => {
      state.subFolderParent = null;
    },
    displayAllAffectFolderPlotStudies: (state, action: PayloadAction<IFolder>) => {
      state.folders.result =
        state.folders.result?.map((f) =>
          f.idIri === action.payload.idIri ? action.payload : f
        ) ?? null;
    },
    allFoldersOnMapHide: (state) => {
      state.folders.result =
        state.folders.result?.map((f) => ({
          ...f,
          displayed: false,
          subs: f.subs?.map((sf) => ({ ...sf, displayed: false })) ?? null,
        })) ?? null;
    },
    singleFolderOnMapShow: (state, action: PayloadAction<IFolder>) => {
      const ap = action.payload;

      state.folders.result =
        state.folders.result?.map((f) => {
          if (f.idIri === ap.parent) {
            // if f = parent folder, we display subfolder
            return {
              ...f,
              subs:
                f.subs?.map((sf) =>
                  sf.idIri === ap.idIri ? { ...sf, displayed: true } : sf
                ) ?? null,
            };
          } else if (f.idIri === ap.idIri) {
            //if f is the folder to update, we display folder
            return { ...f, displayed: true };
          } else {
            // if no matching, return f
            return f;
          }
        }) ?? null;
    },
    singleFolderOnMapHide: (state, action: PayloadAction<IFolder>) => {
      const ap = action.payload;
      state.folders.result =
        state.folders.result?.map((f) => {
          if (f.idIri === ap.parent) {
            // if f = parent folder, we hide subfolder
            return {
              ...f,
              subs:
                f.subs?.map((sf) =>
                  sf.idIri === ap.idIri ? { ...sf, displayed: false } : sf
                ) ?? null,
            };
          } else if (f.idIri === ap.idIri) {
            //if f is the folder to update, we hide folder
            return {
              ...f,
              displayed: false,
              subs: f.subs?.map((sf) => ({ ...sf, displayed: false })) ?? null,
            };
          } else {
            // if no matching, return f
            return f;
          }
        }) ?? null;
    },
    updatePlotStudyCount: (
      state,
      action: PayloadAction<{
        folderIdIri: string;
        count: number;
      }>
    ) => {
      const newFolders = (f: IFolder) => {
        return f.idIri === action.payload.folderIdIri
          ? {
              ...f,
              plotStudiesCount: f.plotStudiesCount + action.payload.count,
              displayed: false,
            }
          : f.subs
            ? {
                ...f,
                subs: f.subs.map((sf) => {
                  return sf.idIri === action.payload.folderIdIri
                    ? {
                        ...sf,
                        plotStudiesCount: sf.plotStudiesCount + action.payload.count,
                        displayed: false,
                      }
                    : sf;
                }),
              }
            : f;
      };
      if (state.folders.result)
        state.folders.result = state.folders.result?.map(newFolders);
      if (state.filteredFolders.result)
        state.filteredFolders.result = state.filteredFolders.result?.map(newFolders);
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // *********************************************************
      // ******************** FETCH FOLDERS **********************
      // *********************************************************
      .addCase(fetchFoldersThunk.pending, (state) => {
        state.folders.apiStatus = APIStatus.PENDING;
        state.folders.result = null;
        state.folders.error = undefined;
      })
      .addCase(
        fetchFoldersThunk.fulfilled,
        (state, action: PayloadAction<Folders>) => {
          state.folders.apiStatus = APIStatus.IDLE;
          state.folders.error = undefined;
          state.folders.result = action.payload;
        }
      )
      .addCase(fetchFoldersThunk.rejected, (state, action: PayloadAction<any>) => {
        state.folders.apiStatus = APIStatus.REJECTED;
        state.folders.error = action.payload;
      })
      .addCase(fetchFoldersFilteredThunk.pending, (state) => {
        state.filteredFolders.apiStatus = APIStatus.PENDING;
        state.filteredFolders.result = null;
        state.filteredFolders.error = undefined;
      })
      .addCase(
        fetchFoldersFilteredThunk.fulfilled,
        (state, action: PayloadAction<Folders>) => {
          state.filteredFolders.apiStatus = APIStatus.IDLE;
          state.filteredFolders.error = undefined;
          state.filteredFolders.result = action.payload;
        }
      )
      .addCase(
        fetchFoldersFilteredThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.filteredFolders.apiStatus = APIStatus.REJECTED;
          state.filteredFolders.error = action.payload;
        }
      );
    // *********************************************************
    // ******* FETCH FOLDER PLOT STUDIES BY FOLDER ID *********
    // *********************************************************
    builder
      .addCase(fetchFolderPlotStudiesThunk.pending, (state) => {
        state.folders.apiStatus = APIStatus.PENDING;
        state.folders.error = undefined;
      })
      .addCase(
        fetchFolderPlotStudiesThunk.fulfilled,
        (
          state,
          action: PayloadAction<{
            folderIdIri: string;
            isSub: boolean;
            plotStudies: PlotStudies;
            forDisplay: boolean;
          } | null>
        ) => {
          state.folders.apiStatus = APIStatus.IDLE;

          if (action.payload !== null) {
            const { folderIdIri, isSub, plotStudies, forDisplay } = action.payload;
            const updateFolders = (folder: IFolder) => {
              return isSub
                ? // if subfolder
                  {
                    ...folder,
                    subs:
                      folder.subs?.map((sf) => {
                        return sf.idIri === folderIdIri
                          ? {
                              ...sf,
                              plotStudies: plotStudies,
                              displayed: forDisplay,
                            }
                          : sf;
                      }) ?? null,
                  }
                : folder.idIri === folderIdIri
                  ? //if folder
                    { ...folder, plotStudies: plotStudies, displayed: forDisplay }
                  : folder;
            };

            state.folders.error = undefined;
            if (state.folders.result !== null) {
              state.folders.result = state.folders.result.map(updateFolders);
            }
            state.filteredFolders.error = undefined;
            if (state.filteredFolders.result !== null) {
              state.filteredFolders.result =
                state.filteredFolders.result.map(updateFolders);
            }
            if (isSub && folderIdIri === state.openedFolder.subfolder?.idIri) {
              state.openedFolder.subfolder = {
                ...state.openedFolder.subfolder,
                plotStudies: plotStudies,
              };
            } else if (folderIdIri === state.openedFolder.folder?.idIri) {
              state.openedFolder.folder = updateFolders(
                state.openedFolder.folder as IFolder
              );
            }
          }
        }
      )
      .addCase(
        fetchFolderPlotStudiesThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.folders.apiStatus = APIStatus.REJECTED;
          state.folders.error = action.payload;
        }
      );
    // *********************************************************
    // ******************** CREATE FOLDER **********************
    // *********************************************************
    builder
      .addCase(folderCreateThunk.pending, (state) => {
        state.folders.apiStatus = APIStatus.PENDING;
        state.folders.error = undefined;
      })
      .addCase(
        folderCreateThunk.fulfilled,
        (state, action: PayloadAction<IFolder>) => {
          const ap = action.payload;

          state.folders.apiStatus = APIStatus.IDLE;
          state.folders.error = undefined;
          if (ap.parent) {
            state.folders.result =
              state.folders.result?.map((f) =>
                f.idIri === ap.parent
                  ? {
                      ...f,
                      subCount: f.subCount + 1,
                      subs:
                        f.subs?.concat({
                          ...ap,
                          indexId: f.subCount + 1,
                        }) ?? null,
                    }
                  : f
              ) ?? null;
          } else {
            if (state.folders.result) {
              const indexedAp = { ...ap, indexId: state.folders.result.length + 1 };
              state.folders.result = state.folders.result?.concat(indexedAp);
            } else {
              state.folders.result = [{ ...ap, indexId: 1 }];
            }
          }
        }
      )
      .addCase(folderCreateThunk.rejected, (state, action: PayloadAction<any>) => {
        state.folders.apiStatus = APIStatus.REJECTED;
        state.folders.error = action.payload;
      });
    // *********************************************************
    // ******************** UPDATE FOLDER **********************
    // *********************************************************
    builder
      .addCase(folderUpdateThunk.pending, (state) => {
        state.folders.apiStatus = APIStatus.PENDING;
        state.folders.error = undefined;
      })
      .addCase(
        folderUpdateThunk.fulfilled,
        (state, action: PayloadAction<IFolder>) => {
          const ap = action.payload;

          state.folders.apiStatus = APIStatus.IDLE;
          state.folders.error = undefined;
          state.filteredFolders.apiStatus = APIStatus.IDLE;
          state.filteredFolders.error = undefined;
          if (ap.parent) {
            state.folders.result =
              state.folders.result?.map((f) =>
                f.idIri === ap.parent
                  ? {
                      ...f,
                      subs:
                        f.subs?.map((sf) =>
                          sf.idIri === ap.idIri ? { ...ap, indexId: sf.indexId } : sf
                        ) ?? null,
                    }
                  : f
              ) ?? null;
            state.filteredFolders.result =
              state.filteredFolders.result?.map((f) =>
                f.idIri === ap.parent
                  ? {
                      ...f,
                      subs:
                        f.subs?.map((sf) =>
                          sf.idIri === ap.idIri ? { ...ap, indexId: sf.indexId } : sf
                        ) ?? null,
                    }
                  : f
              ) ?? null;
          } else {
            state.folders.result =
              state.folders.result?.map((f) =>
                f.idIri === ap.idIri
                  ? { ...f, name: ap.name, markerColor: ap.markerColor }
                  : f
              ) ?? null;
            state.filteredFolders.result =
              state.filteredFolders.result?.map((f) =>
                f.idIri === ap.idIri
                  ? { ...f, name: ap.name, markerColor: ap.markerColor }
                  : f
              ) ?? null;
          }
          if (state.openedFolder.folder?.idIri === ap.idIri) {
            state.openedFolder.folder = ap;
            state.openedFolder.subfolder = null;
          }

          if (state.openedFolder.subfolder?.idIri === ap.idIri) {
            state.openedFolder.subfolder = ap;
          }
        }
      )
      .addCase(folderUpdateThunk.rejected, (state, action: PayloadAction<any>) => {
        state.folders.apiStatus = APIStatus.REJECTED;
        state.folders.error = action.payload;
      });
    // *********************************************************
    // ******************** DELETE FOLDER **********************
    // *********************************************************
    builder
      .addCase(folderDeleteThunk.pending, (state) => {
        state.folders.apiStatus = APIStatus.PENDING;
        state.folders.error = undefined;
      })
      .addCase(
        folderDeleteThunk.fulfilled,
        (state, action: PayloadAction<IFolder>) => {
          const ap = action.payload;
          state.folders.apiStatus = APIStatus.IDLE;
          state.folders.error = undefined;
          state.filteredFolders.apiStatus = APIStatus.IDLE;
          state.filteredFolders.error = undefined;

          if (ap.parent) {
            state.folders.result =
              state.folders.result?.map((f) =>
                f.idIri === ap.parent
                  ? {
                      ...f,
                      subCount: f.subCount - 1,
                      subs:
                        f.subs
                          ?.filter((sf) => sf.idIri !== ap.idIri)
                          .map((sf, i) => ({ ...sf, indexId: i + 1 })) ?? null,
                    }
                  : f
              ) ?? null;
            state.filteredFolders.result =
              state.filteredFolders.result?.map((f) =>
                f.idIri === ap.parent
                  ? {
                      ...f,
                      subCount: f.subCount - 1,
                      subs:
                        f.subs
                          ?.filter((sf) => sf.idIri !== ap.idIri)
                          .map((sf, i) => ({ ...sf, indexId: i + 1 })) ?? null,
                    }
                  : f
              ) ?? null;
          } else {
            state.folders.result =
              state.folders.result
                ?.filter((f) => f.idIri !== ap.idIri)
                .map((f, i) => ({ ...f, indexId: i + 1 })) ?? null;
            state.filteredFolders.result =
              state.filteredFolders.result
                ?.filter((f) => f.idIri !== ap.idIri)
                .map((f, i) => ({ ...f, indexId: i + 1 })) ?? null;
          }
        }
      )
      .addCase(folderDeleteThunk.rejected, (state, action: PayloadAction<any>) => {
        state.folders.apiStatus = APIStatus.REJECTED;
        state.folders.error = action.payload;
      });
    builder
      .addCase(fetchSyntheseFolderThunk.pending, (state) => {
        state.folderRightPanelDatas.apiStatus = APIStatus.PENDING;
        state.folderRightPanelDatas.result = null;
        state.folderRightPanelDatas.error = undefined;
      })
      .addCase(
        fetchSyntheseFolderThunk.fulfilled,
        (state, action: PayloadAction<ISubFolderDataResults | null>) => {
          state.folderRightPanelDatas.apiStatus = APIStatus.IDLE;
          state.folderRightPanelDatas.error = undefined;
          state.folderRightPanelDatas.result = action.payload;
        }
      )
      .addCase(fetchSyntheseFolderThunk.rejected, (state, action) => {
        state.folderRightPanelDatas.apiStatus = APIStatus.REJECTED;
        state.folderRightPanelDatas.result = null;
        state.folderRightPanelDatas.error = action.error;
      });
  },
});

export default foldersSlice.reducer;
export const foldersActions = foldersSlice.actions;
export const getFoldersState = (state: RootState) => state.folders;
