import { useLocation } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import plotActions from '../../../redux/plot/actions';
import { getPlotState } from '../../../redux/plot/reducer';
import { dvfActions, getDvfState } from '../../dvf/dvfSlice';
import { foldersActions } from '../../folders/foldersSlice';
import { mailshotsActions } from '../../mailshots/mailshotsSlice';
import { getMapState, mapActions } from '../../map/mapSlice';
import { studyParamsActions } from '../../study/slices/studyParamsSlice';
import { studyActions } from '../../study/slices/studySlice';
import { getPanelState, panelsActions } from '../panelsSlice';

function useRightPanel() {
  const dispatch = useAppDispatch();
  const path = useLocation();
  const { dvfVisible } = useAppSelector(getDvfState);
  const { displayBuildingCentroid } = useAppSelector(getPlotState);
  const { townHall } = useAppSelector(getMapState);
  const { rightPanelDisplay } = useAppSelector(getPanelState);

  function closeRightPanel() {
    if (path.pathname === '/') {
      dispatch(panelsActions.rightPanelClose());
    } else {
      dispatch(panelsActions.rightPanelCloseOnDashboard());
    }

    dispatch(studyActions.reset());
    dispatch(studyParamsActions.reset());
    dispatch(mailshotsActions.resetWithoutModels());

    dispatch(foldersActions.folderRightPanelDatasReset());
    if (dvfVisible) dispatch(dvfActions.setDvfOnMap(false));
    if (displayBuildingCentroid) dispatch(plotActions.buildingCentroid.set(false));
    if (townHall.displayed)
      dispatch(mapActions.townHallDisplaySet({ displayed: false }));
  }

  return { closeRightPanel, rightPanelDisplay };
}

export default useRightPanel;
