import { decodeToken } from './utils';

function checkUrlType(
  queries: any
): Pick<IConnectionProcessQueries, 'redirectionUrl' | 'phId' | 'sbId'> {
  let redirectionUrl: ConnectionProcessRedirectionUrl = '/';
  let sbId: string | null = queries.sweepBright_external_id ?? null;
  let phId: string | null = queries.ph_id ?? null;
  const decodedToken = decodeToken(queries.token);
  const isOrpi = decodedToken?.firm?.name === 'Orpi';

  if (sbId) {
    redirectionUrl = isOrpi ? '/pdf-orpi' : '/pdf-urbanease';
  }
  // orpi-terrain redirection with PH id
  if (queries.isALand) {
    redirectionUrl = isOrpi ? '/pdf-orpi-terrain' : '/pdf-urbanease-terrain';
  }

  return { redirectionUrl, sbId, phId };
}

function queriesParser(queries: any): IConnectionProcessQueries {
  try {
    const isLand = Boolean(queries.isALand);

    // if isLand, lat/lng/department are loaded with folder request
    // then they are parsed to null
    const department =
      queries.departement_code && queries.departement_code !== 'null'
        ? queries.departement_code
        : null;
    const lat =
      queries.latitude && queries.latitude !== 'null'
        ? parseFloat(queries.latitude)
        : null;
    const lng =
      queries.longitude && queries.longitude !== 'null'
        ? parseFloat(queries.longitude)
        : null;

    const canLoadPlot = Boolean(department && lat && lng);

    // check url redirection type
    const checkType = checkUrlType(queries);

    const parsed: IConnectionProcessQueries = {
      token: queries.token ?? null,
      refreshToken: queries.refresh_token ?? null,
      canLoadPlot,
      department,
      lat,
      lng,
      phAccessToken: queries.ph_access_token ?? null,
      isLand,
      ...checkType,
    };

    return parsed;
  } catch (error) {
    throw new Error('QueriesParser error');
  }
}

export default queriesParser;
