import { delay } from 'lodash';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import { getPdfState, pdfActions } from '../../../externalPdf/pdfSlice';
import { fetchSweepbrightPdfDataThunk } from '../../../externalPdf/services/thunks/fetchSweepbrightPdfDataThunk';
import fetchFolderDatas from '../../../externalPdf/services/thunks/landPDF/requests/fetchFolderDatas';
import {
  ConnectionWorkFlowEnum,
  PdfDatasProcessStepEnum,
} from '../../utils/connexionProcessEnums';
import { CONNEXION_PROCESS_DELAY } from './ConnectionProcessContent';
import { useConnectionProcessContext } from './ConnextionProcessContext';

function FetchPdfDataProcess() {
  const { processDatas, setWorkflowState, setProcessDatas } =
    useConnectionProcessContext();
  const { sweepbrightData, pricehubbleFolderDatas } = useAppSelector(getPdfState);
  const [internalStep, setInternalStep] = useState<PdfDatasProcessStepEnum>(
    PdfDatasProcessStepEnum.IDLE
  );
  const dispatch = useAppDispatch();

  // PH Process
  const fetchPHFolder = async (folderId: string | null) => {
    try {
      if (!folderId || !processDatas.phAccessToken) {
        throw new Error();
      }

      setInternalStep(PdfDatasProcessStepEnum.DATAS_PENDING);
      const res = await fetchFolderDatas({
        entityId: folderId!,
        phAccessToken: processDatas.phAccessToken!,
      });
      dispatch(
        pdfActions.setPricehubbleFolderInfosDatas({
          apiStatus: APIStatus.FULFILLED,
          noData: false,
          result: res,
        })
      );
      const addr = res.property.location.address;

      setProcessDatas({
        ...processDatas,
        lat: res.property.location.coordinates[0],
        lng: res.property.location.coordinates[1],
        department: addr.postalCode?.substring(0, 2),
      });
      setInternalStep(PdfDatasProcessStepEnum.DATAS_FULFILLED);
    } catch (error) {
      setInternalStep(PdfDatasProcessStepEnum.DATAS_REJECTED);
      dispatch(
        pdfActions.setPricehubbleFolderInfosDatas({
          apiStatus: APIStatus.REJECTED,
          noData: true,
          result: null,
        })
      );
    }
  };

  useEffect(() => {
    if (
      sweepbrightData.apiStatus === APIStatus.REJECTED ||
      pricehubbleFolderDatas.apiStatus === APIStatus.REJECTED
    ) {
      setInternalStep(PdfDatasProcessStepEnum.DATAS_REJECTED);
    }

    if (
      (processDatas.isLand &&
        sweepbrightData.apiStatus === APIStatus.FULFILLED &&
        pricehubbleFolderDatas.apiStatus === APIStatus.FULFILLED) ||
      (!processDatas.isLand && sweepbrightData.apiStatus === APIStatus.FULFILLED)
    ) {
      setInternalStep(PdfDatasProcessStepEnum.DATAS_FULFILLED);
    }
  }, [sweepbrightData, pricehubbleFolderDatas]);

  useEffect(() => {
    switch (internalStep) {
      case PdfDatasProcessStepEnum.IDLE:
        setInternalStep(PdfDatasProcessStepEnum.PROCESS_INIT);
        break;
      case PdfDatasProcessStepEnum.PROCESS_INIT:
        delay(() => {
          if (processDatas.phId) {
            // if land, add lat/lng/department to queries for fetch plot on next step
            fetchPHFolder(processDatas.phId);
          }

          dispatch(
            fetchSweepbrightPdfDataThunk({
              id: processDatas?.sbId ?? null,
            })
          );
          setInternalStep(PdfDatasProcessStepEnum.DATAS_PENDING);
        }, CONNEXION_PROCESS_DELAY);
        break;
      case PdfDatasProcessStepEnum.DATAS_FULFILLED:
        setInternalStep(PdfDatasProcessStepEnum.END_PROCESS);
        break;
      case PdfDatasProcessStepEnum.DATAS_REJECTED:
        delay(() => {
          // if no folder datas, after 2 seconds redirect to home
          setWorkflowState(ConnectionWorkFlowEnum.REDIRECT_TO_DEFINED_URL);
        }, 2000);
        break;
      case PdfDatasProcessStepEnum.END_PROCESS:
        if (processDatas.redirectionUrl === '/pdf-orpi-terrain') {
          // if is land, need to load plot after ph datas are fetched
          setWorkflowState(ConnectionWorkFlowEnum.START_FETCH_PLOT_PROCESS);
        } else {
          setWorkflowState(ConnectionWorkFlowEnum.REDIRECT_TO_DEFINED_URL);
        }
        break;
      default:
        break;
    }
  }, [internalStep]);

  return (
    <div>
      {internalStep === PdfDatasProcessStepEnum.DATAS_PENDING && (
        <p>Chargement des données du PDF ...</p>
      )}

      {internalStep === PdfDatasProcessStepEnum.DATAS_FULFILLED && (
        <p>Données du PDF chargée</p>
      )}

      {internalStep === PdfDatasProcessStepEnum.DATAS_REJECTED && (
        <p>Erreur de chargement des données initiales</p>
      )}
    </div>
  );
}

export default FetchPdfDataProcess;
