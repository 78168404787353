import { memo, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useAppSelector } from '../../../../App/store';
import { getPanelState } from '../../../panels/panelsSlice';
import { getStudyParamsState } from '../../../study/slices/studyParamsSlice';
import { getMapState } from '../../mapSlice';

const ChangeView: React.FC = memo(() => {
  const { rightPanelDisplay } = useAppSelector(getPanelState);
  const { type } = useAppSelector(getStudyParamsState);

  const { mapCenter, zoom } = useAppSelector(getMapState);
  const map = useMap();

  useEffect(() => {
    if (rightPanelDisplay) {
      const offsetPixels = 0.06; // Adjust this value as needed for your desired offset
      const currentCenter = mapCenter; // Get the current map center
      // Calculate the new center with an offset to the right
      const newCenter: L.LatLngTuple = [
        currentCenter[0],
        currentCenter[1] + offsetPixels / zoom,
      ];
      map.setView(newCenter, zoom);
    } else {
      map.setView(mapCenter, zoom);
    }
  }, [mapCenter, zoom, type === 'plotStudy']);

  return null;
});

ChangeView.displayName = 'ChangeView';
export default memo(ChangeView);
