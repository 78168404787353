import { Outlet } from 'react-router';
import useSubscriptionAccess from '../../features/company/subscription/useSubscriptionAccess';

function HasPdfLandAccess() {
  const { hasPdfLandAccess } = useSubscriptionAccess();

  return hasPdfLandAccess ? <Outlet /> : null;
}

export default HasPdfLandAccess;
