import { Button } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import AutocompleteCustom from '../../../../shared/components/common/formComponents/AutocompleteCustom';
import FilterSortBloc from '../../../../shared/components/filterSortBloc/FilterSortBloc';
import usePlotStudyStatus from '../../../app/hooks/usePlotStudyStatus';
import { getCompanyState } from '../../../company/companySlice';
import {
  displayManagerActions,
  getDisplayManagerState,
} from '../../../displayManager/displayManagerSlice';
import { fetchAllFolderPlotStudiesForDisplayThunk } from '../../../displayManager/services/thunks/fetchAllFolderAndSubsPlotStudiesForDisplayThunk';
import IndividualCircularLoader from '../../../loaders/components/individualCircularLoader/IndividualCircularLoader';
import { modalsActions } from '../../../modals/modalsSlice';
import SectionHeader from '../../../panels/components/leftPanelModal/menuTabs/prospection/sectionHeader/SectionHeader';
import PrioritySelect from '../../../panels/components/rightPanelModal/tabsContent/bodys/components/prospection/management/prioritySelect/PrioritySelect';
import ProspectionContainer from '../../../panels/components/rightPanelModal/tabsContent/bodys/components/prospection/ProspectionContainer';
import AutocompleteInputCities from '../../../subscription/components/AutocompleteInputCities';
import useUsers from '../../../users/useUsers';
import { foldersActions, getFoldersState } from '../../foldersSlice';
import useFolder from '../../hooks/useFolder';
import { fetchFoldersFilteredThunk } from '../../services/thunks/foldersThunk';
import Folder from '../folder/Folder';
import styles from './folderContainer.module.scss';

const FolderContainer = () => {
  const dispatch = useAppDispatch();
  usePlotStudyStatus();
  const { usersForAutocomplete } = useUsers({});
  const { statusForAutocomplete } = usePlotStudyStatus();
  const { filteredFolders, filteredFoldersApiStatus, folderList } = useFolder({
    forceLoadFiltered: true,
  });

  // Get openedFolder directly from state to ensure we have the latest value
  const { openedFolder } = useAppSelector(getFoldersState);

  const { allFoldersDisplayed, isFoldersPlotGeomLoading } = useAppSelector(
    getDisplayManagerState
  );
  const { entities } = useAppSelector(getDisplayManagerState);
  const { companyIdIri, companyId } = useAppSelector(getCompanyState);
  const cookieName = 'inputValueFilterSortFolder' + companyId;
  const [cookies, setCookie, removeCookie] = useCookies([cookieName]);
  const [sortKey, setSortKey] = useState<string>(cookies[cookieName]?.sortKey ?? '');
  const [order, setOrder] = useState<Order>(cookies[cookieName]?.order ?? 'asc');
  const [filter, setFilter] = useState<IFilterFolder>({
    user: cookies[cookieName]?.user ?? [],
    status: cookies[cookieName]?.status ?? [],
    priority: cookies[cookieName]?.priority ?? 'none',
    city: cookies[cookieName]?.city ?? [],
  });

  // Create a ref to store references to folder elements
  const foldersRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  // Flag to ensure we only scroll once
  const hasScrolledRef = useRef(false);
  // Track last scrolled ID to prevent redundant scrolling
  const lastScrolledToRef = useRef<string | null>(null);

  const handleChange = (data: IFilterFolder) => {
    setFilter(data);
  };

  const handleFilterSortSearch = () => {
    setCookie(cookieName, { ...filter, sortKey, order });
    dispatch(fetchFoldersFilteredThunk({ companyIdIri, isDashboard: false }));
  };

  const handleClearSearch = () => {
    setFilter({
      user: [],
      status: [],
      priority: 'none',
      city: [],
    });
    setSortKey('');
    setOrder('asc');
    removeCookie(cookieName);
    dispatch(
      fetchFoldersFilteredThunk({
        companyIdIri,
        isDashboard: location.pathname === '/dashboard',
      })
    );
  };

  useEffect(() => {
    dispatch(foldersActions.persistantToOpenedFolderSet());
  }, []);

  useEffect(() => {
    return () => {
      //close all deployed folders on close panel
      dispatch(foldersActions.openedFolderReset());
    };
  }, []);

  const handleDisplayAllFolders = (e: ChangeEventCustom) => {
    if (e.target.checked) {
      dispatch(
        fetchAllFolderPlotStudiesForDisplayThunk({ folders: filteredFolders })
      );
    } else {
      dispatch(displayManagerActions.entitiesRemoveByType('folder'));
    }
  };

  // Reset scroll flag when folder data changes
  useEffect(() => {
    hasScrolledRef.current = false;
  }, [folderList, filteredFolders]);

  // A single, unified scroll effect that handles both cases
  useEffect(() => {
    // Only run if we haven't scrolled yet for this state update
    if (hasScrolledRef.current) return;

    // Determine what we need to scroll to
    let targetId: string | null = null;

    // Priority 1: Scroll to subfolder if it exists
    if (openedFolder.subfolder?.idIri) {
      targetId = `subfolder-${openedFolder.subfolder.idIri}`;
    }
    // Priority 2: Scroll to folder if it exists and no subfolder is open
    else if (openedFolder.folder?.idIri && !openedFolder.subfolder) {
      targetId = `folder-${openedFolder.folder.idIri}`;
    }

    // Don't scroll if there's no target or we've already scrolled to this element
    if (!targetId) return;

    // Check if we have the element in our refs
    const elementToScroll = foldersRefs.current[targetId];

    if (elementToScroll) {
      // Perform the scroll and update our tracking refs
      elementToScroll.scrollIntoView({ behavior: 'smooth', block: 'start' });
      hasScrolledRef.current = true;
      lastScrolledToRef.current = targetId;
    }
  }, [openedFolder.folder, openedFolder.subfolder]);

  const handleFolderModal = () => {
    dispatch(modalsActions.folderEdit(true));
  };

  // Register ref callback function
  const registerFolderRef = (id: string, element: HTMLDivElement | null) => {
    if (element) {
      foldersRefs.current[id] = element;
    }
  };

  return (
    <div className={styles.folderSection}>
      <SectionHeader
        checkAll={allFoldersDisplayed}
        handleCheckAllChange={handleDisplayAllFolders}
        handleAdd={handleFolderModal}
        disabled={
          entities.apiStatus === APIStatus.PENDING || isFoldersPlotGeomLoading
        }
        type="dossier"
      />

      <ProspectionContainer title="Filtre Dossiers">
        <div className={styles.filter}>
          <AutocompleteCustom
            options={usersForAutocomplete}
            value={filter.user}
            placeholder="Filtrer par responsable"
            multiple
            onChange={(value: any) => {
              handleChange({ ...filter, user: value as ISelectItem[] });
            }}
          />
          <AutocompleteCustom
            options={statusForAutocomplete()}
            value={filter.status}
            placeholder="Filtrer par statut"
            multiple
            onChange={(value: any) => {
              handleChange({ ...filter, status: value as any[] });
            }}
          />
          <AutocompleteInputCities
            multiple
            selectedCity={filter.city}
            onChange={(value: AutocompleteCity | AutocompleteCity[] | null) => {
              handleChange({ ...filter, city: value as AutocompleteCity[] | null });
            }}
          />
          <PrioritySelect
            value={filter.priority}
            onUpdate={(value: UpdateStudyManagmentProps) => {
              handleChange({ ...filter, ...value } as IFilterFolder);
            }}
            className={styles.prioritySelect}
            displayLabel={false}
            noValuePriority
          />
          <FilterSortBloc
            setSortKey={setSortKey}
            setOrder={setOrder}
            selectItems={[
              { value: '', display: 'Tri initial' },
              { value: 'name', display: 'Nom' },
              { value: 'responsable', display: 'Responsable' },
              { value: 'status', display: 'Statut' },
              { value: 'priority', display: 'Priorité' },
              { value: 'inseeCode', display: 'Commune' },
            ]}
            valueOrder={order}
            valueSortKey={sortKey}
            hasSortPlaceholder={false}
            fullWidth
          />
          <div className={styles.buttons}>
            <Button onClick={handleFilterSortSearch}>Appliquer</Button>
            <Button onClick={handleClearSearch}>Effacer</Button>
          </div>
        </div>
      </ProspectionContainer>

      <div className={styles.folderContainer}>
        {filteredFoldersApiStatus === APIStatus.PENDING ? (
          <IndividualCircularLoader size={100} />
        ) : isEmpty(filteredFolders) ? (
          <p className={styles.noElement}>Aucun dossier</p>
        ) : (
          filteredFolders
            ?.filter((f) => !f.parent)
            .map((folder) => (
              <Folder
                key={folder.idIri}
                folder={folder}
                isSubfolder={false}
                registerFolderRef={registerFolderRef}
              />
            ))
        )}
      </div>
    </div>
  );
};

export default FolderContainer;
