import { yupResolver } from '@hookform/resolvers/yup';
import { cloneDeep } from 'lodash';
import { KeyboardEvent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import getBiggestPropertyObjectFromArray from '../../../shared/utils/getBiggestPropertyObjectFromArray';
import { getAuthState } from '../../auth/authSlice';
import useCompany from '../../company/hooks/useCompany';
import { getMapState } from '../../map/mapSlice';
import styles from '../shared/styles/pdfRightPanelModal.module.scss';

import { IPdfLandingPageProps } from '../../../pages/pdfLandingPage/PdfLandingPage';
import ProgressBarCustom from '../../loaders/components/progressBarCustom/ProgressBarCustom';
import { pdfActions } from '../pdfSlice';
import fetchProspecCityIdThunk from '../services/thunks/landPDF/fetchProspecCityIdThunk';
import PdfRightPanelModalWrapper from '../shared/components/PdfRightPanelModalWrapper';
import useRightPanelPDF from '../shared/hooks/useRightPanelPdf';
import { geolocDataCity, geolocDataStreet } from '../shared/utils/utils';
import ButtonContainer from './components/ButtonContainer';
import PlotForm from './components/PlotForm';
import ProspecForm from './components/ProspecForm';
import {
  initialPdfLandForm,
  landFormSbDatasParser,
  validationSchemaPdfLandForm,
} from './utils';

const PdfLandRightPanelModal = ({ type }: IPdfLandingPageProps) => {
  // **** redux selector and dispatch
  const { user } = useAppSelector(getAuthState);
  const { geolocDatas } = useAppSelector(getMapState);
  const {
    parcelle,
    sweepbrightData,
    multiPlotsPdf,
    isMultiPlotSelectorPdf,
    clicked,
    pricehubbleFolderDatas,
    noPHDatas,
    pdfFormData,
    currentInsee,
  } = useRightPanelPDF();

  const dispatch = useAppDispatch();

  // **** hooks ****
  const { getCompanyName } = useCompany();
  const hookFormMethods = useForm<IExternalPdfLandForm>({
    defaultValues: pdfFormData
      ? (pdfFormData as IExternalPdf)
      : initialPdfLandForm(getCompanyName, user, type),
    resolver: yupResolver(validationSchemaPdfLandForm),
  });

  // **** local states ****
  const [pageDisplay, setPageDisplay] = useState<DisplayFormType>('plot');
  const [loaderSteps, setLoaderSteps] = useState<number>(22);
  // **** constants ****
  const {
    handleSubmit,
    formState: { isValid },
    setValue,
    trigger,
    getValues,
  } = hookFormMethods;

  // handles
  const submitForm = (data: IExternalPdfLandForm): void => {
    const cloned = cloneDeep(data);
    const checkForUndefinedValue = (value?: number | null) =>
      value === undefined ? null : value;
    const updatedDatas = {
      ...cloned,
      currentRent: checkForUndefinedValue(cloned.actualRent),
      annualCharges: checkForUndefinedValue(cloned.annualCharges),
      propertyTax: checkForUndefinedValue(cloned.propertyTax),
    };

    dispatch(pdfActions.setPdfLandFormData(updatedDatas));
  };

  useEffect(() => {
    trigger();
  }, [isValid]);
  // set form values after PH request
  useEffect(() => {
    if (noPHDatas) {
      setLoaderSteps(5);
    }
    // affect sb datas to form
    const theoricCapacity: unknown = parcelle?.theoricCapacity;

    landFormSbDatasParser(
      sweepbrightData,
      geolocDatas,
      (theoricCapacity as number) ?? null,
      hookFormMethods
    );

    trigger();
  }, [pricehubbleFolderDatas, parcelle]);
  useEffect(() => {
    if (
      isMultiPlotSelectorPdf &&
      multiPlotsPdf &&
      (multiPlotsPdf?.length ?? 0) > 0
    ) {
      const plot = getBiggestPropertyObjectFromArray(
        multiPlotsPdf,
        'theoricCapacity'
      );
      const addr = sweepbrightData?.property?.location?.address;
      setValue(
        'addressCity',
        plot.city ? geolocDataCity(geolocDatas) : addr ? geolocDataCity(addr) : ''
      );
      setValue(
        'addressStreet',
        plot.streetName ? geolocDataStreet(plot) : addr ? geolocDataStreet(addr) : ''
      );
    }
  }, [multiPlotsPdf]);
  useEffect(() => {
    if (
      geolocDatas &&
      geolocDatas.inseeCode &&
      currentInsee !== geolocDatas.inseeCode
    ) {
      dispatch(pdfActions.setCurrentInsee(geolocDatas.inseeCode));
      dispatch(fetchProspecCityIdThunk(geolocDatas));
    }
    setPageDisplay('plot');
  }, [geolocDatas]);

  useEffect(() => {
    return () => {
      dispatch(pdfActions.setPdfLandFormData(cloneDeep(getValues())));
    };
  }, []);

  const handleKeyDownOnForm = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter' && (e.target as HTMLElement).nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  return (
    <PdfRightPanelModalWrapper>
      <FormProvider {...hookFormMethods}>
        <form
          onSubmit={handleSubmit(submitForm)}
          autoComplete="off"
          className={`${styles.rightPanelModalBody} ${clicked ? styles.hidden : ''}`}
          onKeyDown={handleKeyDownOnForm}
        >
          {/* land and user infos */}
          {pageDisplay === 'plot' && <PlotForm type={type} />}

          {/* comparable properties from prospec */}
          {pageDisplay === 'prospec' && <ProspecForm />}

          <ButtonContainer
            pageDisplay={pageDisplay}
            setPageDisplay={setPageDisplay}
            type={type}
          />
        </form>
      </FormProvider>
      {clicked && (
        <div className={styles.progressBarContainer}>
          <ProgressBarCustom height={25} totalStep={loaderSteps} widthPercent={80} />
        </div>
      )}
    </PdfRightPanelModalWrapper>
  );
};

export default PdfLandRightPanelModal;
