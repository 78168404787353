import React, { memo } from 'react';
import { Route, Routes } from 'react-router';
import { getAppState } from '../../features/app/appSlice';
import DashboardPage from '../../pages/dashboardPage/DashboardPage';
import ExternalConnection from '../../pages/externalConnection/ExternalConnection';
import HomePage from '../../pages/homePage/HomePage';
import MaintenancePage from '../../pages/MaintenancePage';
import MyAccountPage from '../../pages/myAccountPage/MyAccountPage';
import PasswordResetPage from '../../pages/passwordResetPage/PasswordResetPage';
import PdfLandingPage from '../../pages/pdfLandingPage/PdfLandingPage';
import StreetviewPage from '../../pages/streetviewPage/StreetviewPage';
import View3dPage from '../../pages/View3dPage';
import { useAppSelector } from '../store';
import AuthenticatedRoute from './AuthenticatedRoute';
import HasPdfLandAccess from './HasPdfLandAccess';
import HasUrbaneaseAccess from './HasUrbaneaseAccess';
import OrpiRoute from './OrpiRoute';

function RouteContainer(): React.ReactElement {
  const { maintenancePageOnly } = useAppSelector(getAppState);

  return (
    <Routes>
      {maintenancePageOnly ? (
        <Route path="*" Component={MaintenancePage} />
      ) : (
        // Public routes
        <>
          <Route path="/external_connection" Component={ExternalConnection} />
          <Route path="/reset_password" Component={PasswordResetPage} />
          <Route path="/init_first_password" Component={PasswordResetPage} />

          {/* Authenticated routes */}
          <Route element={<AuthenticatedRoute />}>
            <Route element={<HasUrbaneaseAccess />}>
              <Route path="/dashboard" Component={DashboardPage} />
              <Route
                path="/pdf-urbanease"
                element={<PdfLandingPage type="URBANEASE_CLASSIC" />}
              />
              <Route path="/3d-view" element={<View3dPage />} />
              <Route path="/street-view" element={<StreetviewPage />} />
            </Route>
            <Route element={<HasPdfLandAccess />}>
              <Route
                path="/pdf-urbanease-terrain"
                element={<PdfLandingPage type="URBANEASE_LAND" />}
              />
            </Route>
            <Route element={<OrpiRoute />}>
              <Route
                path="/pdf-orpi"
                element={<PdfLandingPage type="ORPI_CLASSIC" />}
              />
              <Route
                path="/pdf-orpi-terrain"
                element={<PdfLandingPage type="ORPI_LAND" />}
              />
            </Route>

            {/* this route must be accessible on all accesses but with restricted tabs if not any urbanease access */}
            <Route path="/account" Component={MyAccountPage} />
          </Route>

          <Route path="/" Component={HomePage} />

          {/* Default pages */}
          <Route path="*" Component={HomePage} />
        </>
      )}
    </Routes>
  );
}

export default memo(RouteContainer);
