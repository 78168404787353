import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import getIdFromIdIri from '../../lib/parsersTools/getIdFromIdIri';
import { fetchDataById, postEntity } from '../../services/axiosFiles/genericCrud';
import { appActions } from '../app/appSlice';
import { fetchCompanyUserThunk } from '../users/services/usersThunks';
import { companyApiToStoreParser } from './companyParser';

export const fetchCompanyThunk = createAsyncThunk(
  'company/fetchCompanyThunk',
  async (params: { companyIdIri: string; userId: number }, { dispatch }) => {
    try {
      if (!params.companyIdIri) throw new Error('No company idIri found');
      if (!params.userId) throw new Error('No user id found');

      const result = await fetchDataById(params.companyIdIri);
      const parsed = companyApiToStoreParser(result);

      if (parsed?.id && params.userId) {
        const subscription = parsed.subscription;
        if (subscription?.isPdfInterfaceAccess || subscription?.isPdfLandAccess) {
          dispatch(appActions.setDisplayNoUrbaneaseAccessModal(true));
        } else {
          dispatch(appActions.setDisplayNoUrbaneaseAccessModal(false));
        }
        return parsed;
      } else {
        throw new Error('company result error');
      }
    } catch (error: any) {
      return Promise.reject(error);
    }
  }
);
export const changeCompanyThunk = createAsyncThunk(
  'company/changeCompanyThunk',
  async (params: { company: TokenCompany | null }, { dispatch, getState }) => {
    try {
      const userId = (getState() as RootState).auth.userId;
      if (!params.company) throw new Error('No company id found');
      if (!userId) throw new Error('No user id found');

      dispatch({ type: 'app/changeCompany' });
      dispatch(fetchCompanyThunk({ companyIdIri: params.company.idIri, userId }));
      const companyId = getIdFromIdIri(params.company.idIri);
      dispatch(
        fetchCompanyUserThunk({
          companyId,
          userId,
          isAuth: true,
        })
      );
      return params.company;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
export const setAuthenticatedCompanyThunk = createAsyncThunk(
  'company/setAuthenticatedCompanyThunk',
  async ({
    companyId,
    userId,
  }: {
    companyId: number | null;
    userId: number | null;
  }) => {
    try {
      if (!companyId) throw new Error('company id missing');
      if (!userId) throw new Error('user id missing');

      await postEntity({
        endpoint: '/set_authenticated_company',
        body: {
          company_id: companyId,
          user_id: userId,
        },
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
